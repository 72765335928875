import React, { useState } from "react";
import CustomTabs from "components/CustomTabs/CustomTabs";
import ListCustomers from "./controllers/ListCustomers";
import CreateCustomers from "./controllers/CreateCustomers";

export default function Index() {
  const [stateView, setStateView] = useState({
    form: false,
    list: true,
  });

  const hanclderViewComponent = (newState) => {
    setStateView(newState);
  };
  return (
    <div className="mr-4">
      <CustomTabs>
        <CustomTabs.Button
          tittleButton="LISTADO DE CLIENTES"
          fnOnclick={() =>
            hanclderViewComponent({
              form: false,
              list: true,
            })
          }
          activated={stateView.list}
        />
        <CustomTabs.Button
          tittleButton="CREAR CLIENTE"
          fnOnclick={() =>
            hanclderViewComponent({
              form: true,
              list: false,
            })
          }
          activated={stateView.form}
        />
      </CustomTabs>
      {stateView.list && <ListCustomers />}
      {stateView.form && <CreateCustomers />}
    </div>
  );
}
