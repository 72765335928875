import React, { useState } from 'react';
import { get, put, post } from 'api/AsyncHttpRequest';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import ButtonUploadInputs from './ButtonUploadInputs';
import { useSnackbar } from 'notistack';
import { isEnterKey, isEmpty } from 'utils/helpers';

export default function ProductCoste() {
  const { enqueueSnackbar } = useSnackbar();
  const [price, setPrice] = useState(0);
  const [qty, setQty] = useState(0);
  const urlProducts = process.env.REACT_APP_API_BASE_URL;
  const [productsGet, setProductsGet] = useState([]);
  const [productsGetAll, setProductsGetAll] = useState([]);
  const [initialPrice, setInitialPrice] = useState(0);
  const [initialQty, setInitialQty] = useState(0);
  const [searchSku, setSearchSku] = useState('');
  const [searchProduct, setSearchProduct] = useState('');

  const responseGetProducts = (response) => {
    if (response?.length > 0) {
      setProductsGet(response);
    } else {
      setSearchProduct('');
      setProductsGet([]);
      enqueueSnackbar('No se encontraron coincidencias', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };
  const responseGetProductsALL = (response) => {
    if (response?.length > 0) {
      setProductsGetAll(response);
    } else {
      setSearchSku('');
      setProductsGetAll([]);
      enqueueSnackbar('No se encontraron coincidencias', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const callbackUpdateInput = (response) => {
    if (response?.data?.data === true) {
      enqueueSnackbar('Excelente producto actualizado exitosamente!', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      setProductsGetAll([]);
      setProductsGet([]);
    } else {
      enqueueSnackbar('Algo salio mal, intentalo nuevamente', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const handlerSearchProduct = (e) => {
    const valueSearch = e.target.value;
    setSearchProduct(valueSearch);
    if (isEnterKey(e)) {
      if (!isEmpty(e.target.value)) {
        get(
          `${urlProducts}products/api/productsinput/${e.target.value}`,
          responseGetProducts
        );
      }
      if (isEmpty(e.target.value)) {
        enqueueSnackbar('El campo no puede estar vacio', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    }

    if (valueSearch === '') {
      setSearchProduct('');
      setProductsGet([]);
    }
  };

  const handlerSearchProductAll = (e) => {
    const valueSearch = e.target.value;
    setSearchSku(valueSearch);
    if (isEnterKey(e)) {
      if (!isEmpty(e.target.value)) {
        get(
          `${urlProducts}products/api/productsskuadd/${e.target.value}`,
          responseGetProductsALL
        );
      }
      if (isEmpty(e.target.value)) {
        enqueueSnackbar('El campo no puede estar vacio', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    }

    if (valueSearch === '') {
      setSearchSku('');
      setProductsGetAll([]);
    }
  };

  const handlerReset = () => {
    setSearchSku('');
    setSearchProduct('');
    setProductsGet([]);
    setProductsGetAll([]);
  };

  const handlerUpdateProductInventory = (item) => {
    let params = {
      cantidad: parseInt(qty) || item?.cantidad,
      costo: parseInt(price) || item?.costo,
      id: item.sku,
    };
    put(
      `${urlProducts}products/api/productsupdateinput`,
      params,
      callbackUpdateInput
    );
  };

  const handlerAddProductInventory = (item) => {
    let params = {
      id_products_input: 1,
      cantidad: parseInt(initialQty),
      costo: parseInt(initialPrice),
      id_product: item.sku,
    };

    post(
      `${urlProducts}products/api/productsaddinput`,
      params,
      callbackUpdateInput
    );
  };
  return (
    <div className="p-10">
      <ButtonUploadInputs />
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="flex flex-col justify-start items-start my-2 border-2 rounded-2xl border-gray-800 p-2 mx-2">
          <p className="w-full m-0 my-2 text-xl text-center">
            Seccion para actualizar costo y cantidad a un producto
          </p>
          <div className="w-full flex flex-wrap justify-between">
            <p className="m-0 mt-2 font-semibold text-2xl">Producto</p>
            <input
              className="border-2 border-gray-400 rounded-md m-1 p-1"
              placeholder="Buscar..."
              onKeyPress={handlerSearchProduct}
              onChange={(e) => setSearchProduct(e.target.value)}
              value={searchProduct}
            />
            <Button
              htmlFor="contained-button-file"
              variant="contained"
              color="primary"
              component="span"
              onClick={handlerReset}
            >
              BORRAR
            </Button>
          </div>

          <div className="w-full flex flex-col  items-start">
            {productsGet &&
              productsGet.map((item, index) => (
                <div
                  key={index}
                  className="w-full flex flex-wrap md:flex-row justify-between items-center"
                >
                  <p className="m-0 normal-case text-sm flex flex-row justify-center items-center">
                    {`${item.sku} | ${item.producto} `}
                  </p>
                  <div className="flex flex-wrap items-center">
                    <div className="my-3 sm:m-3">
                      <input
                        className="border-2 border-gray-400 rounded-md w-32  text-base"
                        name="price"
                        placeholder="COSTO"
                        onChange={(e) => setPrice(e.target.value)}
                        defaultValue={`${item?.costo}`}
                      />
                      <p className="text-blue-800" style={{ fontSize: '10px' }}>
                        Costo del producto
                      </p>
                    </div>
                    <div className="my-3 sm:m-3">
                      <input
                        className="border-2 border-gray-400 rounded-md w-20 text-base"
                        name="qty"
                        placeholder="CANT."
                        onChange={(e) => setQty(e.target.value)}
                        defaultValue={` ${item?.cantidad}`}
                      />
                      <SaveIcon
                        className=""
                        style={{ fontSize: 30 }}
                        onClick={() => handlerUpdateProductInventory(item)}
                      />
                      <p className="text-blue-800" style={{ fontSize: '10px' }}>
                        Cantidad del producto
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className="flex flex-col justify-start items-start my-2 border-2 rounded-2xl border-gray-800 p-2 mx-2">
          <p className="w-full m-0 my-2 text-xl text-center">
            Seccion para agregar costo y cantidad a un producto
          </p>
          <p
            className="w-full text-red-800 p-0 text-center"
            style={{ fontSize: '10px', margin: '0px' }}
          >
            * Solo se puede buscar por SKU
          </p>
          <div className="w-full flex flex-wrap justify-between">
            <p className="m-0 mt-2 font-semibold text-2xl">Producto</p>
            <input
              className="border-2 border-gray-400 rounded-md m-1 p-1"
              placeholder="Buscar..."
              onChange={(e) => setSearchSku(e.target.value)}
              onKeyPress={handlerSearchProductAll}
              value={searchSku}
            />
            <Button
              htmlFor="contained-button-file"
              variant="contained"
              color="primary"
              component="span"
              onClick={handlerReset}
            >
              BORRAR
            </Button>
          </div>

          <div className="w-full flex flex-col  items-start">
            {productsGetAll &&
              productsGetAll.map((item, index) => (
                <div
                  key={index}
                  className="w-full flex flex-wrap md:flex-row justify-between items-center"
                >
                  <p className="m-0 normal-case text-sm flex flex-row justify-center items-center">
                    {`${item.sku} | ${item.name_product} `}
                  </p>

                  <div className="flex flex-wrap items-center">
                    <div className="my-3 sm:m-3">
                      <input
                        className="border-2 border-gray-400 rounded-md w-32 text-base"
                        name="price"
                        placeholder="COSTO"
                        onChange={(e) => setInitialPrice(e.target.value)}
                      />
                      <p className="text-blue-800" style={{ fontSize: '10px' }}>
                        Costo del producto
                      </p>
                    </div>
                    <div className="my-3 sm:m-3">
                      <input
                        className="border-2 border-gray-400 rounded-md w-20 text-base"
                        name="qty"
                        placeholder="CANT."
                        onChange={(e) => setInitialQty(e.target.value)}
                      />
                      <SaveIcon
                        className=""
                        style={{ fontSize: 30 }}
                        onClick={() => handlerAddProductInventory(item)}
                      />
                      <p className="text-blue-800" style={{ fontSize: '10px' }}>
                        Cantidad del producto
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
