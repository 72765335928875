import React, { useContext, useState } from 'react';
import CommonContext from 'context/context';
import CustomTabs from 'components/CustomTabs/CustomTabs';
import ListProducts from './controllers/ListProducts';
import FormCreateProduct from './controllers/FormCreateProduct';
import ProductCoste from './controllers/ProductCoste';

const Index = () => {
  const [stateView, setStateView] = useState({
    form: false,
    list: false,
    update: true,
  });
  const { t } = useContext(CommonContext);
  const hanclderViewComponent = (newState) => {
    setStateView(newState);
  };
  return (
    <div className="mr-4">
      <CustomTabs>
        <CustomTabs.Button
          tittleButton={t('products.button_update_products')}
          fnOnclick={() =>
            hanclderViewComponent({
              form: false,
              list: false,
              update: true,
            })
          }
          activated={stateView?.update}
        />
        <CustomTabs.Button
          tittleButton={t('products.button_create')}
          fnOnclick={() =>
            hanclderViewComponent({
              form: true,
              list: false,
              update: false,
            })
          }
          activated={stateView?.form}
        />
        <CustomTabs.Button
          tittleButton={t('products.button_list_product')}
          fnOnclick={() =>
            hanclderViewComponent({
              form: false,
              list: true,
              update: false,
            })
          }
          activated={stateView?.list}
        />
      </CustomTabs>
      {stateView?.list && <ListProducts />}
      {stateView?.form && <FormCreateProduct />}
      {stateView?.update && <ProductCoste />}
    </div>
  );
};

export default Index;
