import React from "react";

const CustomTabs = ({ children }) => {
  return <div className="bg-black flex flex-row mb-3">{children}</div>;
};

CustomTabs.Button = ({ tittleButton, fnOnclick, activated }) => (
  <button
    className={
      activated
        ? "bg-black text-white mx-3 py-2 border-b-4 border-indigo-500"
        : "bg-black text-white mx-3 py-2"
    }
    onClick={fnOnclick}
  >
    {tittleButton}
  </button>
);

export default CustomTabs;
