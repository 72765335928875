import React, { useState } from 'react';
import { putNode } from 'api/AsyncHttpRequest';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import getDate from 'utils/helpers';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
function OrderDetail({
  viewDetails,
  setViewDetails,
  setMessageError,
  setMessageSucces
}) {
  let hoy = new Date();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const urlBaseNode = process.env.REACT_APP_API_BASE_URL_NODE;
  const [dataTable, setDataTable] = useState([]);
  const [observations, setobservations] = useState('');
  const [pay, setPay] = useState(0);
  const [geoLocationSave, setGeoLocationSave] = useState({
    latitude_coordinate: '',
    altitude_coordinate: '',
  });
  navigator.geolocation.getCurrentPosition(function (position) {
    setGeoLocationSave({
      latitude_coordinate: position.coords.latitude,
      altitude_coordinate: position.coords.longitude,
    });
  });
  const [diabledButton, setDiabledButton] = useState(false);
  const respponseCallback = (response) => {
    setDataTable(response);
  };
  const callbackResponseSave = (response) => {
    setDiabledButton(false);
    
    if (response?.message === "DELIVERED_UPDATE_SUCCESS") {
      enqueueSnackbar('Pedido entregado con exito', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      setMessageSucces(true);
      setMessageError(false);
      setDiabledButton(false);
      setViewDetails({ view: !viewDetails.view });
    } else {
      enqueueSnackbar(
        'Algo salio mal en la entrega del pedido, intentalo nuevamente',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }
      );
      setMessageError(true);
      setMessageSucces(false);
    }
  };

  const handlerUpdateOrder = () => {
    setDiabledButton(true);
    let dataSave = {
      id: parseInt(viewDetails.data.id),
      delivery_date: getDate(hoy),
      observation_del: observations,
      delivered: 'SI',
      pay: pay !== null ? parseFloat(pay) : 0,
      latitude_coordinate: geoLocationSave.latitude_coordinate,
      altitude_coordinate: geoLocationSave.altitude_coordinate,
    };
    putNode(
      `${urlBaseNode}external/update-route`,
      dataSave,
      callbackResponseSave
    );
  };

  const alertFinishDeliveri = () => {
    const update = window.confirm('Quieres finalizar la entrega');
    if (update) {
      handlerUpdateOrder();
    } else {
      setDiabledButton(false);
      enqueueSnackbar('Entrega pendiente por cerrar', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  function handleChange(event) {
    const newValue = event.target.value.replace(/[.,]/g, '');
    setPay(newValue);
  }

  return (
    <div className="w-full sm:w-4/5 md:w-2/5 border-2 mt-5 rounded-2xl border-blue-500 p-4">
      <div className="flex flex-col items-start ">
        <div className="w-full flex justify-end">
          <IconButton
            color="secondary"
            aria-label="add an alarm"
            onClick={() => setViewDetails({ view: !viewDetails.view })}
          >
            <HighlightOffIcon />
          </IconButton>
        </div>
        {
          viewDetails.data.full_name && <div className="flex flex-wrap md:flex-row justify-start items-end">
          <h3 className="m-0 text-xl font-semibold">Nombre del cliente: </h3>
          <p className="m-0 ml-3 text-base">{viewDetails.data.full_name}</p>
        </div>
        }
        
        {viewDetails.data.address && (
          <div className="flex flex-wrap md:flex-row justify-start items-end">
            <h3 className="m-0 text-xl font-semibold">
              Direccion de entrega:{' '}
            </h3>
            <p className="m-0 ml-3 text-base">{viewDetails.data.address}</p>
          </div>
        )}

        {viewDetails.data.phone && (
          <div className="flex flex-wrap md:flex-row justify-start items-end">
            <h3 className="m-0 text-xl font-semibold">Telefono de cliente: </h3>
            <p className="m-0 ml-3 text-base">{viewDetails.data.phone}</p>
          </div>
        )}
         {viewDetails.data.quantity && (
          <div className="flex flex-wrap md:flex-row justify-start items-end">
            <h3 className="m-0 text-xl font-semibold">Cantidad: </h3>
            <p className="m-0 ml-3 text-base">{viewDetails.data.quantity}</p>
          </div>
        )}
        {viewDetails.data.total && (
          <div className="flex flex-wrap md:flex-row justify-start items-end">
            <h3 className="m-0 text-xl font-semibold">Total: </h3>
            <p className="m-0 ml-3 text-base">$ {viewDetails.data.total}</p>
          </div>
        )}
         {
          viewDetails.data.observation && <div className="flex flex-wrap md:flex-row justify-start items-end">
          <h3 className="m-0 text-xl font-semibold">Nota: </h3>
          <p className="m-0 ml-3 text-base">{viewDetails.data.observation}</p>
        </div>
        }
      </div>

      {/* <ProductsDetail dataTable={viewDetails.items} /> */}
      <div className="flex flex-wrap sm:flex-row md:flex-row lg:flex-row justify-start items-center my-2">
        <p className="m-0">Valor pagado</p>
        <input
          className="border-b-4 border-2 w-full border-gray-400 rounded-md m-0 my-3 px-2 h-12 shadow-lg"
          name="pay"
          placeholder="$"
          onChange={(e) => handleChange(e)}
          // {...register("observations", { maxLength: 200 })}
        />
      </div>
      <div className="flex flex-wrap sm:flex-row md:flex-row lg:flex-row justify-start items-center my-2">
        <p className="m-0">Observaciones</p>

        <textarea
          className="border-b-4 border-2 w-full border-gray-400 rounded-md m-0 my-3 px-2 h-16 shadow-lg"
          name="observation_del"
          placeholder="Observaciones"
          onChange={(e) => setobservations(e.target.value)}
          // {...register("observations", { maxLength: 200 })}
        />
      </div>
      <Button
        disabled={diabledButton}
        onClick={() => alertFinishDeliveri()}
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<DoneAllIcon />}
      >
        ENTREGAR
      </Button>
    </div>
  );
}

export default OrderDetail;
