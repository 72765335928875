import React from "react";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { getOnlyDate } from "utils/helpers";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import Button from "@material-ui/core/Button";
import { getNode } from 'api/AsyncHttpRequest';
import Swal from 'sweetalert2';
import { useSnackbar } from "notistack";

function ResumeDownload({setIsLoading}) {
  let hoy = new Date();
  const urlBaseNode = process.env.REACT_APP_API_BASE_URL_NODE;
  const [startDate, setStartDate] = React.useState(hoy);
  const [finaltDate, setfinaltDate] = React.useState(hoy);
  const [delivered, setDelivered] = React.useState("ALL");
  const [ruteNumber, setruteNumber] = React.useState(1);
  const { enqueueSnackbar } = useSnackbar();

  registerLocale("es", es);
  setDefaultLocale("es");
  const handleChange = (date) => {
    setStartDate(date);
  };
  const handleFinalChange = (date) => {
    setfinaltDate(date);
  };

  const cbResponseByOrder = (response, value) => {
    setIsLoading(false)
    if (response.status === 200) {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "cuadre.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    } else {
      console.error(response.statusText);
    }
  };

  const handlerOpenOrder = () => {
    setIsLoading(true)
    fetch(
      `${urlBaseNode}external/generar-csv?startDate=${getOnlyDate(
        startDate
      ).toString()}&endDate=${getOnlyDate(
        finaltDate
      ).toString()}&delivered=${delivered}`
    )
      .then((response) => cbResponseByOrder(response))
      .catch((error) => console.error(error));
  };
  const cbResponseResume =(response)=>{
    setIsLoading(false)
    if (response.totalDelivered) {
         Swal.fire({
        title: `<strong>Resumen entregas de la ruta ${ruteNumber}</strong>`,
        icon: 'info',
        html: `<b>Total entregas:</b> ${response.totalDelivered} <br>` +
        `<b>Total efectivo:</b> ${response.totalReceived}`,
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'OK',
        denyButtonText: `Don't save`
      })
    } else {
        enqueueSnackbar(
            "No hay resultados para esta busqueda",
            {
              variant: "warning",
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            }
          );
    }
   
  }

  const handlerResume = () => {
    setIsLoading(true)
    getNode(`${urlBaseNode}external/fast-resume?startDate=${getOnlyDate(
        startDate
      ).toString()}&endDate=${getOnlyDate(
        finaltDate
      ).toString()}&route=${ruteNumber}`, cbResponseResume)
  };

  const handleSelectChange = (event) => {
    setDelivered(event.target.value);
  };

  const fnGetRoutesByRoute = (val) => {
    setruteNumber(val);
  };

  return (
    <div>
      <div className="flex flex-row justify-between w-full px-3">
        <div className="flex flex-row w-auto items-center">
          <label className="form-label font-semibold pr-2">Fecha Inicial</label>
          <DatePicker
            selected={startDate}
            onChange={handleChange}
            locale="es"
            className="date-picker"
          />
          <label className="form-label font-semibold pr-2">Fecha Final</label>
          <DatePicker
            selected={finaltDate}
            onChange={handleFinalChange}
            locale="es"
            className="date-picker"
          />
          <div>
            <label htmlFor="delivered">Entregado</label>
            <select
              id="delivered"
              value={delivered}
              onChange={handleSelectChange}
            >
              <option value="">--Seleccione--</option>
              <option value="SI">SI</option>
              <option value="NO">NO</option>
              <option value="ALL">TODO</option>
            </select>
          </div>
          <div className="w-full flex justify-start">
            {" "}
            <Button
              onClick={() => fnGetRoutesByRoute(1)}
              type="submit"
              variant="contained"
              color="secondary"
              style={{ marginLeft: "5px" }}
            >
              R1
            </Button>
            <Button
              onClick={() => fnGetRoutesByRoute(2)}
              type="submit"
              variant="contained"
              color="secondary"
              style={{ marginLeft: "5px" }}
            >
              R2
            </Button>
            <Button
              onClick={() => fnGetRoutesByRoute(3)}
              type="submit"
              variant="contained"
              color="secondary"
              style={{ marginLeft: "5px" }}
            >
              R3
            </Button>
          </div>
        </div>
        <div className="flex flex-row">
        
          <button
            onClick={() => handlerOpenOrder()}
            className="bg-green-800 text-white p-4 ml-3"
          >
            <ListAltIcon />
            Descargar resumen
          </button>
          <button
            onClick={() => handlerResume()}
            className="bg-green-800 text-white p-4 ml-3"
          >
            <BeenhereIcon />
            Generar cuadre rapido
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResumeDownload;
