export const isEmpty = (value) => !value || String(value).trim().length === 0;

export const isEnterKey = (event) =>
  event.which === 13 || event.keyCode === 13 || event.key === 'Enter';

export const isClick = (event) =>
  event.which === 65 || (event.keyCode === 65 && true);

export const calculatefinalcost = (customType, coste) => {
  let result = 0;
  const typeCustomer = (type, value) => {
    if (type === value) {
      return true;
    }
    return false;
  };

  if (typeCustomer(customType, 'COR')) {
    result = coste * 0.1 + coste;
  }
  if (typeCustomer(customType, 'NOR')) {
    if (coste > 0 && coste <= 10000) {
      result = coste * 0.3 + coste;
    }
    if (coste >= 10001 && coste <= 20000) {
      result = coste * 0.25 + coste;
    }
    if (coste >= 20001 && coste <= 30000) {
      result = coste * 0.2 + coste;
    }
    if (coste >= 30001 && coste <= 100000) {
      result = coste * 0.15 + coste;
    }
    if (coste >= 100001) {
      result = coste * 0.1 + coste;
    }
  }
  return result;
};

export default function getDate(date) {
  if (date === null || date === '') {
    return ''
  }
  const f = new Date(date);

  let dia = f.getDate(); // 30
  let mes = f.getMonth() + 1; // 0 (Enero)
  let year = f.getFullYear(); // 2018
  let hour = f.getHours(); // 15
  let minutes = f.getMinutes(); // 30
  let seconds = f.getSeconds(); // 10
  f.getMilliseconds(); // 999
  f.getTimezoneOffset(); // 0
  f.getTime(); // 1517326210999 (Tiempo Unix)

  return `${year}-${mes}-${dia} ${hour}:${minutes}:${seconds}`;
}
export function getOnlyDate(date) {
  const f = new Date(date);

  let dia = f.getDate(); // 30
  let mes = f.getMonth() + 1; // 0 (Enero)
  let year = f.getFullYear(); // 2018
  f.getMilliseconds(); // 999
  f.getTimezoneOffset(); // 0
  f.getTime(); // 1517326210999 (Tiempo Unix)

  return `${year}-${mes < 10 ? `0${mes}` : mes}-${dia < 10 ? `0${dia}` : dia}`;
}
export function getHour(date) {
  const f = new Date(date);
  let hora = f.getHours(); // 15
  let minuto = f.getMinutes(); // 30
  f.getSeconds(); // 10
  f.getMilliseconds(); // 999
  f.getTimezoneOffset(); // 0
  f.getTime(); // 1517326210999 (Tiempo Unix)

  return `${hora}:${minuto}`;
}
