import { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../auth/ProvideAuth';

const Login = () => {
  const [dataLogin, setDataLogin] = useState({ user: '', password_h: '' });
  const auth = useContext(AuthContext);
  let history = useHistory();


  const login = () => {
    auth.signin((response) => {
      history.push('/home');
    }, dataLogin);
  };
  useEffect(() => {
    auth?.user && history.push('/home');
    !auth?.user && history.push('/login');
  }, [auth, history]);
  return (
    <Fragment>
      {!auth?.user && (
        <div className="w-full h-screen px-5 bg-gray-700 grid justify-items-center content-center">
          <div className="border-2 rounded-lg bg-white p-4 flex flex-col w-full sm:w-96">
            <div className="flex flex-row justify-center items-center">
              <p className="text-purple-900 text-3xl font-semibold">CRM</p>
              <p className="text-center my-3 ml-4">Login</p>
            </div>
            <input
              className="border-2 hover:border-gray-600 focus-within:border-gray-400 rounded-md p-2 my-2"
              placeholder="Usuario"
              onChange={(e) =>
                setDataLogin({ ...dataLogin, user: e.target.value })
              }
            />
            <input
              type="password"
              className="border-2 hover:border-gray-600 focus-within:border-gray-400 rounded-md p-2 my-2"
              placeholder="Contraseña"
              onChange={(e) =>
                setDataLogin({ ...dataLogin, password_h: e.target.value })
              }
            />

            <button className="bg-blue-700 py-3 rounded-md" onClick={login}>
              <p className="text-white">Ingresar</p>
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Login;
