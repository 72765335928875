import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import getDate from 'utils/helpers';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { post } from 'api/AsyncHttpRequest';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// import ButtonUploadCustomers from "./ButtonUploadCustomers"

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function CreateCustomers() {
  const userid = localStorage.getItem('id');
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  let hoy = new Date();
  // const [nameCustomer, setNameCustomer] = useState('');
  const [messageSucces, setMessageSucces] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const urlBase = process.env.REACT_APP_API_BASE_URL_NODE;
  const { register, handleSubmit } = useForm();
  const [state, setState] = React.useState({
    document_type: '',
    customer_type: '',
  });

  const [diabledButton, setDiabledButton] = useState(false);
  const onSubmit = (data) => {
    const dataSave = {
      address_customer: data?.address_customer,
      cellphone: data?.cellphone,
      email: data?.email,
      full_name: data?.full_name,
      user_id: userid,
      identification_number: data?.identification_number,
      recurrence: data?.recurrence || 10,
      birthday_date: getDate(data?.birthday)
    };

    if (dataSave?.full_name !== '') {
      setDiabledButton(true);
      post(
        `${urlBase}customers/create`,
        Object.assign(dataSave, {
          last_call: getDate(hoy),
          document_type: state.document_type || 'DF',
          customer_type: state.customer_type || 'NOR',
        }),
        callbackSaveCustomer
      );
    } else {
      enqueueSnackbar('El nombre es requerido', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const callbackSaveCustomer = (response) => {
    setDiabledButton(false);
    if (response?.data === 'CUSTOMER_CREATED_SUCCESSFULLY') {
      history.push('/home');
      setMessageSucces(true);
      setMessageError(false);
    } else {
      enqueueSnackbar('Algo salio mal Intentalo nuevamente', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    setTimeout(() => {
      setMessageError(false);
      setMessageSucces(false);
    }, 20000);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <>
      {/* <ButtonUploadCustomers /> */}
      <form
        className="flex flex-wrap justify-center items-center border-2 p-5 border-gray-500 rounded-xl"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">
            Tipo Cliente
          </InputLabel>
          <Select
            native
            onChange={(e) => handleChange(e)}
            label="Tipo documento"
            inputProps={{
              name: 'customer_type',
            }}
          >
            <option aria-label="None" value="" />
            <option value={'COR'}>Corporativo</option>
            <option value={'NOR'}>Normal</option>
          </Select>
        </FormControl>
        <div className="mx-2 my-2 flex flex-col">
          <input
            className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
            placeholder="Nombre"
            // onChange={(e) => setNameCustomer(e.target.value)}
            // value={nameCustomer}
            {...register('full_name', { maxLength: 50 })}
          />
          <label className="text-xs ml-1 pl-1">Nombre completo</label>
        </div>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-age-native-simple">
            Tipo documento
          </InputLabel>
          <Select
            native
            onChange={(e) => handleChange(e)}
            label="Tipo documento"
            inputProps={{
              name: 'document_type',
            }}
          >
            <option aria-label="None" value="" />
            <option value={'CE'}>Cedula Extranjeria</option>
            <option value={'CC'}>Cedula Ciudadania</option>
          </Select>
        </FormControl>

        <div className="mx-2 my-2 flex flex-col">
          <input
            className="border-2 border-gray-400 text-gray-500 w-56 rounded-md m-1 text-lg p-1"
            placeholder="Numero de cedula"
            {...register('identification_number', {
              maxLength: 15,
            })}
          />
          <label className="text-xs ml-1 pl-1">No. documento</label>
        </div>
        <div className="mx-2 my-2 flex flex-col">
          <input
            className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
            placeholder="Direccion"
            {...register('address_customer', {
              maxLength: 80,
            })}
          />
          <label className="text-xs ml-1 pl-1">Dirección</label>
        </div>
        <div className="mx-2 my-2 flex flex-col">
          <input
            className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
            placeholder="Correo"
            {...register('email', { maxLength: 50 })}
          />
          <label className="text-xs ml-1 pl-1">Correo</label>
        </div>
        <div className="mx-2 my-2 flex flex-col">
          <input
            className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
            placeholder="Celular"
            label="Celular"
            {...register('cellphone', { maxLength: 15 })}
          />
           <label className="text-xs ml-1 pl-1">Celular</label>
        </div>
        <div className="mx-2 my-2 flex flex-col">
          <input
            type="number"
            className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
            placeholder="Recurrencia"
            label="Recurrencia"
            {...register('recurrence', { maxLength: 15 })}
          />
           <label className="text-xs ml-1 pl-1">Recurrencia</label>
        </div>
        <div className="mx-2 my-2 flex flex-col">
        <input
          className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
          type="date"
          placeholder="Fecha nacimiento"
          label="Fecha nacimiento"
          {...register("birthday")}
        />
          <label className="text-xs ml-1 pl-1">Fecha nacimiento</label>
      </div>

        <div className="mx-2 my-2">
          <Button
            disabled={diabledButton}
            type="submit"
            variant="contained"
            color="primary"
          >
            Guardar
          </Button>
        </div>
      </form>

      {messageSucces && (
        <div className={classes.root}>
          <Alert severity="success">
            <AlertTitle>Excelente</AlertTitle>
            Cliente creado — <strong>exitosamente!</strong>
          </Alert>
        </div>
      )}
      {messageError && (
        <div className={classes.root}>
          <Alert severity="error">
            <AlertTitle>Algo salio mal</AlertTitle>
            El cliente no pudo ser creado — <strong>intenta nuevamente!</strong>
          </Alert>
        </div>
      )}
    </>
  );
}
