import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { routes } from './routes';
import Layout from './components/Layout/Layout';
import { ProvideAuth } from './auth/ProvideAuth';
import { PrivateRoute } from './auth/PrivateRoute';
import LoginHome from './pages/Login';

function App() {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <Route exact path="/login" component={LoginHome} />
          <PrivateRoute>
            <Layout>
              {routes.map(({ path, Component }) => (
                <Route exact path={path} component={Component} key={path} />
              ))}
            </Layout>
          </PrivateRoute>
        </Switch>
      </Router>
    </ProvideAuth>
  );
}

export default App;
