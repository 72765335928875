import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import CommonContext from 'context/context';
import getDate, {
  isEmpty,
  isEnterKey,
  calculatefinalcost,
} from 'utils/helpers';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { getNode, post } from 'api/AsyncHttpRequest';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import FormOrder from 'modules/Orders/components/FormOrder';
import { useOrderInformations } from 'modules/Orders/store/useOrderInformations';
// import ButtonUploadCustomers from "./ButtonUploadCustomers"
import ErrorLabel from 'components/Labels/ErrorLabel';
import FoundValue from 'components/Labels/FoundValue';
import InputGetuserController from 'modules/Orders/controllers/InputGetuserController';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function CreateQuotes({ changeTab }) {
  const { t } = useContext(CommonContext);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  let hoy = new Date();
  const [messageSucces, setMessageSucces] = useState(false);
  const [idCustomer, setIdCustomer] = useState();
  const [messageError, setMessageError] = useState(false);
  const urlBase = process.env.REACT_APP_API_BASE_URL;
  const urlBase_node = process.env.REACT_APP_API_BASE_URL_NODE;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [customersGet, setCustomersGet] = useState([]);
  const [namecustomersGet, setNameCustomersGet] = useState('');
  const [state, setState] = React.useState({
    document_type: '',
    customer_type: '',
  });
  const [diabledButton, setDiabledButton] = useState(true);
  const [DisableButtonCustomer, setDisableButtonCustomer] = useState(false);
  const {
    customerInformation,
    setcustomerInformation,
    noCustomer,
    setNoCustomer,
    notProduct,
    setnotProduct,
    productsList,
    setProductsList,
    viewSearchProduct,
    setViewSearchProduct,
    customerType,
    setCustomerType,
    qty,
    setQty,
    detailInvoice,
    setDetailInvoice,
    numberOrder,
    setNumberOrder,
    nameCustomer,
    setNameCustomer,
  } = useOrderInformations();
  const onSubmit = (data) => {
    const dataSave = {
      usersId: localStorage.getItem("id"),
      customersId: idCustomer,
      observation: data?.observartion,
    };

    if (dataSave) {
      setDiabledButton(true);
      post(`${urlBase_node}quotes/create`, dataSave, callbackSaveCustomer);
    } else {
      enqueueSnackbar('El nombre es requerido', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const callbackSaveCustomer = (response) => {
    setDiabledButton(false);
    if (response?.status === 200) {
      changeTab({
        form: false,
        list: true,
        update: false,
      });
      setMessageSucces(true);
      setMessageError(false);
    } else {
      enqueueSnackbar('Algo salio mal Intentalo nuevamente', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    setTimeout(() => {
      setMessageError(false);
      setMessageSucces(false);
    }, 20000);
  };

  const callbackResponseCustomer = (data) => {

    if (data?.data?.length > 0) {
      setNoCustomer('');
      setCustomersGet(data.data);
    } else {
      setNoCustomer('Cliente no encontrado');
    }
  };
  const handlerChange = (e) => {
    setNameCustomer(e.target.value);
    setNameCustomersGet(e.target.value);
    setDiabledButton(true);
  };
  const onclickgeGetCustomer = (event) => {
    if (!isEmpty(nameCustomer)) {
      getNode(
        `${urlBase_node}customers/${nameCustomer}`,
        callbackResponseCustomer
      );
      return;
    } else {
      setNoCustomer('El campo no puede estar vacio');
      return;
    }
  };
  const handlerSelectCustmer = (dataSelected) => {
    setIdCustomer(parseInt(dataSelected.id));
    setNameCustomersGet(dataSelected.full_name);
    setDisableButtonCustomer(true);
    setDiabledButton(false);
    setCustomersGet([]);
  };
  return (
    <>
      <form
        className="flex flex-wrap justify-center items-center border-2 p-5 border-gray-500 rounded-xl"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="mx-2 my-2">
          <p className="m-0 mt-2 font-semibold">{t('orders.label_customer')}</p>
          <input
            autoFocus
            className="border-2 border-gray-400 rounded-md m-o w-full p-1 mb-2"
            onChange={handlerChange}
            placeholder={t('orders.placeholder_search')}
            value={namecustomersGet}
          />
          <Button
            disabled={DisableButtonCustomer}
            onClick={onclickgeGetCustomer}
            variant="contained"
            color="primary"
          >
            Buscar
          </Button>
          <InputGetuserController
            handlerSelectCustmer={handlerSelectCustmer}
            customersGet={customersGet}
          />
          {noCustomer && <ErrorLabel noCustomer={noCustomer} />}
          {customerInformation?.full_name && (
            <FoundValue value={customerInformation?.full_name} />
          )}
        </div>

        <div className="mx-2 my-2 flex flex-col">
          <textarea
            className="border-2 border-gray-400 w-56 rounded-md m-1 text-lg p-1"
            placeholder="Detalle cotización"
            label="Observaciones"
            minLength={5}
            {...register('observartion', {
              required: true,
              minLength: 5,
            })}
          />
          {errors.observartion && (
            <span className="text-red-800">
              Escriba minimo 5 letras, el detalle es requerido
            </span>
          )}

          <Button
            disabled={diabledButton}
            type="submit"
            variant="contained"
            color="primary"
          >
            Guardar
          </Button>
        </div>
      </form>

      {messageSucces && (
        <div className={classes.root}>
          <Alert severity="success">
            <AlertTitle>Excelente</AlertTitle>
            Cliente creado — <strong>exitosamente!</strong>
          </Alert>
        </div>
      )}
      {messageError && (
        <div className={classes.root}>
          <Alert severity="error">
            <AlertTitle>Algo salio mal</AlertTitle>
            El cliente no pudo ser creado — <strong>intenta nuevamente!</strong>
          </Alert>
        </div>
      )}
    </>
  );
}
