import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import getDate from 'utils/helpers';
import { post } from 'api/AsyncHttpRequest';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));
export default function ButtonUploadProducts() {
  const { enqueueSnackbar } = useSnackbar();
  let hoy = new Date();
  const classes = useStyles();
  const urlSaveNewAllProducts = process.env.REACT_APP_API_BASE_URL;
  //const [fileCsv, setFileCsv] = useState()
  const [nameCsv, setNameCsv] = useState();
  const [fileCsvTex, setFileCsvTex] = useState(null);
  const [saving, setSaving] = useState(false);

  const respponseCallback = (response) => {
    setSaving(false);
    if (response?.data?.data === true) {
      enqueueSnackbar('Producto creado exitosamente', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } else {
      enqueueSnackbar('Algo salio mal intentalo nuevamente', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const getValues = (text, position) => {
    let todo = text.split(',');
    return todo[position];
  };
  const parseCSV = (text) => {
    // Obtenemos las lineas del texto
    let lines = text.replace(/\r/g, '').split('\n');
    let val = lines.forEach((line) => {
      if (line !== '') {
        let values = {
          sku: getValues(line, 0),
          name_product: getValues(line, 1),
          photo_link: getValues(line, 2),
          descriptionProd: getValues(line, 3),
          creation_date: getDate(hoy),
          state_products: 1,
        };
        return values;
      }
    });
    // return lines.map((line) => {
    //   // Por cada linea obtenemos los valores
    //   if (line !== '') {
    //     let values = {
    //       sku: getValues(line, 0),
    //       name_product: getValues(line, 1),
    //       photo_link: getValues(line, 2),
    //       descriptionProd: getValues(line, 3),
    //       creation_date: getDate(hoy),
    //       state_products: 1,
    //     };
    //     return values;
    //   }
    // });
    return val;
  };

  const handlerSaveAll = (e) => {
    let file = e.target.files[0];
    setNameCsv(e.target.files[0].name);
    let reader = new FileReader();
    reader.onload = (e) => {
      let lines = parseCSV(e.target.result);
      lines.pop();
      setFileCsvTex(lines);
      //saveAllProducts(lines)
    };
    reader.readAsBinaryString(file);
  };

  const handleSaveAllProducts = () => {
    setSaving(true);
    post(
      `${urlSaveNewAllProducts}products/api/productsall/post`,
      fileCsvTex,
      respponseCallback
    );
  };

  return (
    <div className={`${classes.root} mb-2`}>
      <p
        className="text-red-800 p-0"
        style={{ fontSize: '10px', margin: '0px' }}
      >
        * Importante: el archivo a subir no debe tener cabeceras en las columnas
      </p>
      <input
        accept=".csv"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={(e) => handlerSaveAll(e)}
      />
      <label htmlFor="contained-button-file">
        <Button
          disabled={saving}
          htmlFor="contained-button-file"
          variant="contained"
          color="default"
          className={classes.button}
          component="span"
          startIcon={<CloudUploadIcon />}
        >
          Subir productos
        </Button>
      </label>
      <Button
        disabled={saving}
        htmlFor="contained-button-file"
        variant="contained"
        color="primary"
        className={classes.button}
        component="span"
        onClick={handleSaveAllProducts}
      >
        Guardar
      </Button>
      <div className="font-bold mt-2" id="info">
        {nameCsv}
      </div>
      {saving && <LinearProgress />}
    </div>
  );
}
