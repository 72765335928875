import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CardOrders from './CardOrders';
import { getNode } from 'api/AsyncHttpRequest';
import OrderDetail from './OrderDetail';
import { Alert, AlertTitle } from '@material-ui/lab';
import NotFound from 'components/NotFound/NotFound';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Deliver() {
  const classes = useStyles();
  const [messageSucces, setMessageSucces] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [viewDetails, setViewDetails] = useState({
    view: false,
    data: '',
  });

  return (
    <div>
      <div className="px-2 sm:px-9 pt-5">
        {!viewDetails.view ? (
          <div
            className={`mt-5 w-full flex flex-wrap justify-center items-center ${classes.root}`}
          >
            <CardOrders setViewDetails={setViewDetails} />
          </div>
        ) : (
          <div className="flex w-full justify-center items-center mb-5">
            <OrderDetail    
              setMessageSucces={setMessageSucces}
              setMessageError={setMessageError}
              viewDetails={viewDetails}
              setViewDetails={setViewDetails}
            />
          </div>
        )}
        {/* {messageSucces && (
          <div className={classes.root}>
            <Alert severity="success">
              <AlertTitle>Excelente</AlertTitle>
              Orden entregada — <strong>exitosamente!</strong>
            </Alert>
          </div>
        )}
        {messageError && (
          <div className={classes.root}>
            <Alert severity="error">
              <AlertTitle>Algo salio mal</AlertTitle>
              La orden no se pudo guardar — <strong>intenta nuevamente!</strong>
            </Alert>
          </div>
        )} */}
      </div>
    </div>
  );
}
