import React, { useContext } from 'react';
import FormOrderController from './controllers/FormOrderController';
import SortingOrders from './controllers/SortingOrders';
import CommonContext from 'context/context';
import CustomTabs from 'components/CustomTabs/CustomTabs';

const Index = () => {
  const [viewForm, setViewForm] = React.useState(true);
  const [viewOrders, setViewOrders] = React.useState(false);
  const { t } = useContext(CommonContext);
  const hanclderViewComponent = () => {
    setViewForm(!viewForm);
    setViewOrders(!viewOrders);
  };
  return (
    <div className="mr-4">
      <CustomTabs>
        <CustomTabs.Button
          tittleButton={t('orders.button_create')}
          fnOnclick={hanclderViewComponent}
          activated={viewForm}
        />
        <CustomTabs.Button
          tittleButton={t('orders.button_list_orders')}
          fnOnclick={hanclderViewComponent}
          activated={viewOrders}
        />
      </CustomTabs>

      {viewForm && <FormOrderController />}
      {viewOrders && <SortingOrders />}
    </div>
  );
};

export default Index;
