import create from 'zustand';

export const useOrderInformations = create((set) => {
  return {
    customerInformation: {},
    setcustomerInformation: (information) =>
      set({
        customerInformation: information,
      }),
    noCustomer: '',
    setNoCustomer: (msj) =>
      set({
        noCustomer: msj,
      }),
    notProduct: '',
    setnotProduct: (msj) =>
      set({
        notProduct: msj,
      }),
    productsList: [],
    setProductsList: (products) =>
      set({
        productsList: products,
      }),
    viewSearchProduct: false,
    setViewSearchProduct: (state) =>
      set({
        viewSearchProduct: state,
      }),
    customerType: '',
    setCustomerType: (type) =>
      set({
        customerType: type,
      }),
    nameCustomer: '',
    setNameCustomer: (value) =>
      set({
        nameCustomer: value,
      }),
    qty: 0,
    setQty: (type) =>
      set({
        qty: type,
      }),
    detailInvoice: [],
    setDetailInvoice: (type) =>
      set({
        detailInvoice: type,
      }),
    numberOrder: 0,
    setNumberOrder: (type) =>
      set({
        numberOrder: type,
      }),
  };
});
