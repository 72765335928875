import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const FormOrder = ({ children, handleSubmit, onSubmit }) => {
  return (
    <form
      className="flex flex-col sm:w-full mr-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      {children}
    </form>
  );
};
FormOrder.Label = ({ children }) => (
  <div className="flex flex-wrap  md:flex-wrap justify-start items-start my-2">
    {children}
  </div>
);

FormOrder.Input = ({
  nameLabel,
  placeHolder,
  registername,
  register = () => {},
  onChangeValue,
}) => (
  <div className="flex flex-wrap  md:flex-row lg:flex-row justify-start items-start">
    <p className="m-0 mt-2 font-semibold">{nameLabel}</p>
    <input
      autoFocus
      className="border-2 border-gray-400 rounded-md m-o w-full p-1"
      onKeyPress={onChangeValue}
      placeholder={placeHolder}
      {...register(registername, {
        maxLength: 50,
      })}
    />
  </div>
);

FormOrder.Select = ({ handlerChange, labels }) => (
  <div className="mt-2 mb-3 w-full">
    <FormControl variant="outlined" className="w-full">
      <InputLabel htmlFor="outlined-age-native-simple">
        {labels.title}
      </InputLabel>
      <Select
        native
        onChange={(e) => handlerChange(e)}
        label="Método de pago"
        inputProps={{
          name: 'payment_method',
        }}
      >
        <option aria-label="None" value="" />
        <option value={'EFE'}>{labels.option1}</option>
        <option value={'TRA'}>{labels.option2}</option>
        <option value={'CHE'}>{labels.option3}</option>
      </Select>
    </FormControl>
  </div>
);

FormOrder.Textarea = ({ nameLabel, register, registername }) => (
  <div className="flex flex-wrap sm:flex-row md:flex-row lg:flex-row justify-start items-center">
    <p className="m-0 font-semibold">{nameLabel}</p>
    <textarea
      className="border-b-4 border-2 w-full border-gray-400 rounded-md m-0 mt-1 mb-3 px-2 h-16 shadow-lg"
      name={registername}
      placeholder={nameLabel}
      {...register(registername, { maxLength: 200 })}
    />
  </div>
);

FormOrder.Button = ({ nameButton }) => (
  <button className="bg-green-700 py-3 rounded-md text-white" type="submit">
    {nameButton}
  </button>
);
export default FormOrder;
