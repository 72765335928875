import React from "react";
import PropTypes from "prop-types";
import Sidebar from "../Sidebar/Sidebar";

const Layout = ({ children }) => {
  return (
    <div>
      <Sidebar />
      <div className="p-0 h-screen flex flex-col pt-9 justify-between">
        <main className="ml-16">{children}</main>
      </div>
      {/* <footer
        style={{
          padding: `20px 30px 10px 80px`,
          background: `#000000`,
          color: `#ffffff`,
          marginTop: `0`,
        }}
      >
        © {new Date().getFullYear()}, developed by
        {` `}
        <a href="#!">Luis E. Garcia</a>
      </footer> */}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
