import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { CommonContext } from 'context/context';
import global_es from './translate/es/global.json';
import global_en from './translate/en/global.json';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
i18next.init({
  interpolation: { escapeValue: false },
  lng: 'es',
  resources: {
    en: { global: global_en },
    es: { global: global_es },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <SnackbarProvider maxSnack={3} TransitionComponent={Slide}>
        <CommonContext>
          <App />
        </CommonContext>
      </SnackbarProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
