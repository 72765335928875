import React, { useEffect, useState, useCallback } from 'react';
import { get } from 'api/AsyncHttpRequest';
import NotFound from 'components/NotFound/NotFound';
import StatisticsRound from './CustomRound';
import OrderResume from './OrderResume';
import SalesMonth from './SalesMonth';

export default function StatisticsComponent() {
  const urlBase = process.env.REACT_APP_API_BASE_URL;
  const [data, setData] = useState(null);
  const [buscando, setBuscando] = useState(true);

  const respponseCallback = useCallback((response) => {
    setBuscando(false);
    if (response.length >= 0) {
      setData(response?.[0]);
    } else {
      setData(null);
    }
  }, []);

  useEffect(() => {
    if (buscando) {
      get(
        `${urlBase}bussinessorders/api/bussinessorders/statistics`,
        respponseCallback
      );
    }
  }, [urlBase, respponseCallback, buscando]);
  return (
    <div>
      {data !== null ? (
        <div
          position="primary"
          className={`bg-gray-900 pt-5 flex flex-wrap justify-center items-center`}
        >
          <div className="flex flex-col justify-center items-center mb-4">
            <OrderResume data={data} />
            <StatisticsRound dataIn={data} />
          </div>

          <SalesMonth dataIn={data} />
        </div>
      ) : (
        <NotFound />
      )}
    </div>
  );
}
